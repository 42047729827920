.navbar-brand img {
    width: 120px;
}

table.table.table-borderless {
    th, td {
        border: 0px none !important;
    }
}

.custom-checkbox, .custom-radio {
    input,
    label {
        cursor: pointer;
    }

    label {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}
